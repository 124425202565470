<template>
    <div class="v-wrapper tool--none">
      <div class="v-body">
        <template>
          <div class="search-bars">

            <el-button type="primary" class="obtn" plain @click="() => dialogInfoVisible=true" v-ripple>{{$t('wallet.openWallet')}}</el-button>
            <el-button type="primary" plain :loading="exportButtonLoading" @click="exportHandle" circle v-ripple class="ebtn">
              <i-icon name="iconxinxiguanli_daochu_weixuanzhong" v-if="!exportButtonLoading"></i-icon>
            </el-button>
            <!-- <el-button type="primary" plain :loading="exportButtonLoading" @click="exportHandle" circle v-ripple class="ebtn">
              <i-icon name="iconxinxiguanli_daochu_weixuanzhong"></i-icon>
            </el-button> -->
            <!-- <el-button type="primary" class="border" plain @click="exportHandle" icon="el-icon-download" :loading="exportButtonLoading" v-ripple>{{$t('wallet.export')}}</el-button> -->

            <search-group :options="options" style="float: right;" @click="click" @input="({select,value,Op}) => {searchName=select;searchText=value,searchOption=Op}"
              :field="{select: searchName, value: searchText}"></search-group>
            <el-checkbox v-model="hasChild" style="float:right;line-height:36px;">{{$t('wallet.includeChild')}}</el-checkbox>
          </div>

          <el-table :data="tableData" :height="tableHeight" @sort-change="sortChange" border @header-dragend="saveHeaderDragWidth" class="v-table" size="mini" ref="multipleTable" v-loading="tableLoading" :element-loading-text="tableLoadingText">
            <el-table-column sortable="custom" prop="OccurTime" :label="$t('wallet.time')" :width="TW($t('wallet.time'), 170)" show-overflow-tooltip>
              <p slot-scope="{row}">{{miment(row.OccurTime).format()}}</p>
            </el-table-column>
            <el-table-column sortable="custom" prop="OccurName" :label="$t('wallet.name')" :width="TW($t('wallet.name'), 150)">
              <template slot-scope="{row}">
                <p class="primaryText">{{row.OccurName}}</p>
              </template>
            </el-table-column>
            <el-table-column sortable="custom" prop="OccurMoney" :label="$t('wallet.vpoint')" :width="TW($t('wallet.vpoint'), 80)">
              <template slot-scope="{row}">
                <p v-if="row.OccurMoney>=0" style="color:#4CAF50;">+{{row.OccurMoney}}</p>
                <p v-else style="color:#f44336;">-{{-row.OccurMoney}}</p>
              </template>
            </el-table-column>
            <el-table-column sortable="custom" prop="RemainTotalBalance" :label="$t('wallet.remain')" :width="TW($t('wallet.remain'), 100)">
              <template slot-scope="{row}">
                <p>{{row.RemainMoney}}</p>
              </template>
            </el-table-column>
            <el-table-column sortable="custom" prop="OrderNO" :label="$t('wallet.remarkOrder')" :width="TW($t('wallet.remarkOrder'), 250)">
              <template slot-scope="{row}">
                <p>{{row.OrderNO}}</p>
                <p style="color:#9E9E9E;" v-if="row.Remark && row.Remark.indexOf('订单号:')===0">订单号:<span class="i-link" @click="openDialogOrderDetail(row.Remark.substr(4))">{{row.Remark.substr(4)}}</span></p>
                <p style="color:#9E9E9E;" v-else>{{row.Remark}}</p>
              </template>
            </el-table-column>
            <el-table-column sortable="custom" prop="Name" :label="$t('wallet.user')" min-width="120px">
              <template slot-scope="{row}">
                <p class="primaryText" :title="row.FullPath">{{row.HoldName}} <span class="successText">{{row.CreateUserName}}</span></p>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="v-pager"
            :page-size="pageSize"
            :page-sizes="pageSizes"
            :pager-count="11"
            :current-page="pageIndex"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotalCount"
            @current-change="pageCurrentChange"
            @size-change="pageSizeChange"
            popper-class="custom">
          </el-pagination>

          <vcl-code v-if="false" class="ima-4" :width="400" :height="150"></vcl-code>

          <!-- <div class="page__detail">
            测试页面
          </div> -->
        </template>
      </div>
      <el-dialog :title="$t('wallet.wallet')" append-to-body width="400px" :visible.sync="dialogInfoVisible" custom-class="v-dialog v-dialog--wallet">
        <wallet-info v-if="dialogInfoVisible" @close="() => dialogInfoVisible = false" @send="send"></wallet-info>
      </el-dialog>
      <el-dialog :title="$t('wallet.sendVPoint')" append-to-body custom-class="v-dialog v-dialog--sm v-dialog--mdpadding" :visible.sync="dialogSendVisible">
        <wallet-send v-if="dialogSendVisible" @close="() => dialogSendVisible = false"></wallet-send>
      </el-dialog>
      <el-dialog :visible.sync="orderDetailListVisible" :title="$t('wallet.orderDetail')" custom-class="v-dialog v-dialog--sm v-dialog--mdpadding v-dialog--footer">
        <el-table :data="orderDetailList" class="v-table__customStyle" row-key="ObjectID" style="width:100%;" size="mini" max-height="410" stripe ref="multiTable">
          <el-table-column :label="$t('wallet.vehicleName')" prop="VehicleName" width="150"></el-table-column>
          <el-table-column :label="$t('wallet.holdName')" prop="Name" width="150"></el-table-column>
          <el-table-column :label="$t('wallet.deviceID')" prop="SIM2" width="150">
            <span slot-scope="{row}">{{row.SIM2||row.SIM}}</span>
          </el-table-column>
          <el-table-column :label="$t('wallet.amount')" prop="ServiceEndDate" width="80">
            <template slot-scope="{row}">
              <p>{{row.Months/12}} {{$t('wallet.year')}} </p>
            </template>
          </el-table-column>
          <el-table-column :label="$t('wallet.vipEndDate')">
            <template slot-scope="{row}">
              <p>{{miment(row.EndTime).format('YYYY-MM-DD')}}</p>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer">
          <el-button @click="orderDetailListVisible=false">{{$t('common.close')}}</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import { VclCode } from 'vue-content-loading'
import IDropDown from '@/base/i-dropdown/i-dropdown'
import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
import mixin from '@/views/manage/mixin'
import * as API from '@/api/wallet'
import {Filter, Op} from '@/api/config'
import TreeUser from '@/views/common/tree-user'
import WalletInfo from './wallet-info'
import WalletSend from './wallet-send'
import { saveBlob } from '@/common/utils'
import { QueryMultiOrderDetail } from '@/api/fee'
import miment from 'miment'

export default {
  name: 'wallet',
  data() {
    return {
      miment,
      options: [
        { label: '订单编号', value: 'P.OrderNO', Op: Op.Contains },
        { label: '服务名称', value: 'P.OccurName', Op: Op.Contains },
        { label: '用户', value: 'H.Name', Op: Op.Contains },
        { label: '账号', value: 'U.UserName', Op: Op.Contains },
        { label: '备注', value: 'P.Remark', Op: Op.Contains }
      ],
      riskListOptions: [],
      isAnimating: false, // 是否正在动画
      dialogInfoVisible: false,
      dialogSendVisible: false,
      orderDetailListVisible: false,
      orderDetailList: []
    }
  },
  components: {
    IDropDown,
    IDropDownOption,
    VclCode,
    TreeUser,
    WalletInfo,
    WalletSend
  },
  mixins: [
    mixin
  ],
  watch:{
    '$i18n.locale': 'getOptions'
  },
  created() {
    this.getOptions()
    this.pageIndex = 1
    this.queryHandle()
  },
  methods: {
    click() {
      this.pageIndex = 1
      this.queryHandle()
    },
    getOptions() {
      this.options = [
        { label: this.$t('wallet.orderNo'), value: 'P.OrderNO', Op: Op.Contains },
        { label: this.$t('wallet.name'), value: 'P.OccurName', Op: Op.Contains },
        { label: this.$t('wallet.createUser'), value: 'H.Name', Op: Op.Contains },
        { label: this.$t('wallet.account'), value: 'U.UserName', Op: Op.Contains },
        { label: this.$t('wallet.remark'), value: 'P.Remark', Op: Op.Contains }
      ]
    },
    rowClick(row) {
      this.$router.push(`/risk/people/detail?id=${row.RID}`)
    },
    selectableHandle() {
      return true
      //return row.UserID != this.loginInfo.UserID
    },
    addHandle() {
      // console.log(row)
      this.$router.push('/risk/people/detail')
    },
    queryHandle() {
      const promise = new Promise((resolve, reject) => {
        const filters = []

        this.searchText && this.searchText.length > 0
          && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))

        this.tableLoading = true
        API.Query(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, this.currentHoldId, this.hasChild).then(ret => {
          this.tableData = ret.data.list.slice()
          this.pageTotalCount = ret.data.total
          this.tableLoading = false
          resolve()
        }).catch(err => {
          this.print('error', err.message)
          this.tableLoading = false
          reject(err)
        })
      })
      return promise
    },
    getRiskTypes(ids) {
      const idArr = ids.split(',').map(k => parseInt(k))
      const types = this.riskListOptions.filter(k => {
        return idArr.indexOf(k.RID) != -1
      }).map(k => k.RiskType)
      return types
    },
    exportHandle() {
      this.exportButtonLoading = true
      API.Export(this.currentHoldId, this.hasChild).then(ret => {
        this.exportButtonLoading = false
        saveBlob(ret.data, `${this.$t('wallet.wallet')}.xlsx`)
      })
    },
    refreshHandle() {
      this.pageIndex = 1
      this.queryHandle()
    },
    send() {
      this.dialogInfoVisible = false
      this.dialogSendVisible = true
    },
    sum(list, compare) {
      const temp = list.map(compare)
      let total = 0
      temp.forEach(k => total+=k||0)
      return total
    },
    fetchMultiOrderDetail(orderId) {
      const promise = new Promise((resolve) => {
        QueryMultiOrderDetail(orderId).then(ret => {
          resolve(ret.data)
        })
      })
      return promise
    },
    async openDialogOrderDetail(orderId) {
      const list = await this.fetchMultiOrderDetail(orderId)
      this.orderDetailList = list
      this.orderDetailListVisible = true
    },
  }
}
</script>

<style lang="scss" scoped>
.v-wrapper {
  display: flex;
  align-items: stretch;
  padding: 8px;
  .v-nav {
    width: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
  }
  .v-body {
    width: 0;
    flex-grow: 1;
  }
}
.riskitem {
  color: #f44336;
  display: block;
  width: 80px;
}
.search-bars{
  height: auto;
  overflow: hidden;
  .obtn{
    border-radius: 20px !important;
    float: left;
    padding:6px 16px;
  }
 
  .ebtn{
        font-size: 20px;
        padding: 5px;
        border: 1px solid rgba(24, 144, 255, 0.5);
        background-color: #FFF;
        color: #1890ff;
        float: left;
        margin-right: 15px;
         & ::v-deep .icon{
           display: block;
         }
        & ::v-deep {
          .el-icon-loading{
            display: block;
            font-size: 20px;
            padding: 5px;
          }
        }
      }
}
</style>
